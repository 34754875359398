export const NEB_HAS_CONFIG = [
    "giphy",
    "guggy",
    "github",
    "google",
    "imgur",
];

export const NEB_IS_COMPLEX = [
    "rss",
    "travisci",
];