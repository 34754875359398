import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { BridgeComponent } from "../bridge.component";

@Component({
    selector: "my-bridge-config",
    template: require('./config-screen.bridge.component.html'),
    styles: [require('./config-screen.bridge.component.scss')],
})
export class ConfigScreenBridgeComponent {

    @Input() bridgeComponent: BridgeComponent<any>;
    @ContentChild(TemplateRef, {static: false}) bridgeParamsTemplate: TemplateRef<any>;

    constructor() {
    }
}