import { WidgetComponent } from "../widget.component";
import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

@Component({
    selector: "my-widget-config",
    template: require('./config-screen.widget.component.html'),
    styles: [require('./config-screen.widget.component.scss')],
})
export class ConfigScreenWidgetComponent {

    @Input() widgetComponent: WidgetComponent;
    @ContentChild(TemplateRef, {static: false}) widgetParamsTemplate: TemplateRef<any>;

    constructor() {
    }
}