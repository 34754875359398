import { Component } from "@angular/core";
import { SessionStorage } from "../shared/SessionStorage";

@Component({
    selector: "my-riot",
    template: require('./riot.component.html'),
    styles: [require('./riot.component.scss')],
})
export class RiotComponent {

    constructor() {
    }

    public isAdmin(): boolean {
        return SessionStorage.isAdmin;
    }
}
