import { Component } from "@angular/core";
import { DialogRef, ModalComponent } from "ngx-modialog";
import { BSModalContext } from "ngx-modialog/plugins/bootstrap";

export class LogoutConfirmationDialogContext extends BSModalContext {
}

@Component({
    template: require('./logout-confirmation.component.html'),
    styles: [require('./logout-confirmation.component.scss')],
})
export class AdminLogoutConfirmationDialogComponent implements ModalComponent<LogoutConfirmationDialogContext> {

    constructor(public dialog: DialogRef<LogoutConfirmationDialogContext>) {
    }
}
