import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";

// depending on the env mode, enable prod mode or add debugging modules
//noinspection TypeScriptUnresolvedVariable
if (process.env.ENV === "build") {
    enableProdMode();
}

export function main() {
    return platformBrowserDynamic().bootstrapModule(AppModule).then(function(MODULE_REF) {   if (module["hot"]) {     module["hot"]["accept"]();          if (MODULE_REF.instance["hmrOnInit"]) {       module["hot"]["data"] && MODULE_REF.instance["hmrOnInit"](module["hot"]["data"]);     }     if (MODULE_REF.instance["hmrOnStatus"]) {       module["hot"]["apply"](function(status) {         MODULE_REF.instance["hmrOnStatus"](status);       });     }     if (MODULE_REF.instance["hmrOnCheck"]) {       module["hot"]["check"](function(err, outdatedModules) {         MODULE_REF.instance["hmrOnCheck"](err, outdatedModules);       });     }     if (MODULE_REF.instance["hmrOnDecline"]) {       module["hot"]["decline"](function(dependencies) {         MODULE_REF.instance["hmrOnDecline"](dependencies);       });     }     module["hot"]["dispose"](function(store) {       MODULE_REF.instance["hmrOnDestroy"] && MODULE_REF.instance["hmrOnDestroy"](store);       MODULE_REF.destroy();       MODULE_REF.instance["hmrAfterDestroy"] && MODULE_REF.instance["hmrAfterDestroy"](store);     });   }   return MODULE_REF; });
}

if (document.readyState === "complete") {
    main();
} else {
    document.addEventListener("DOMContentLoaded", main);
}

(<any>String.prototype).hashCode = function () {
    let hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

// HACK: Work around .opener not being available
if (!window.opener && window.parent) window.opener = window.parent;
