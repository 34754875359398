import { Component } from "@angular/core";
import { ScalarClientApiService } from "../../shared/services/scalar/scalar-client-api.service";

@Component({
    selector: "my-scalar-close",
    template: require('./scalar-close.component.html'),
    styles: [require('./scalar-close.component.scss')],
})
export class ScalarCloseComponent {

    constructor(private scalar: ScalarClientApiService) {
    }

    public closeScalar() {
        console.log("Closing scalar...");
        this.scalar.close();
    }
}
